<template>
  <div>
    <v-container fluid>

      <v-row>
        <v-col cols="12">
          <Toolbar/>
        </v-col>
      </v-row>

      <v-row v-if="loading">
        <v-col cols="12" lg="3" v-for="(el,i) in 8" :key="i">
          <v-card class="rounded-lg shadow">
            <v-skeleton-loader type="card,article"/>
          </v-card>
        </v-col>
      </v-row>

      <v-row v-if="!loading && tasks.length === 0">
        <v-col cols="12">
          <v-card class="transparent" flat>
            <v-card-text class="text-center">
              <NoResult/>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

      <v-row v-if="!loading && tasks">
        <v-col class="d-flex flex-column"
               cols="12"
               lg="3"
               v-for="(task,i) in tasks" :key="i">

          <v-card class="rounded-lg shadow flex d-flex flex-column"
                  :to="'/backoffice/final-validation/'+task.task_id">

            <v-img v-if="task.task_photo"
                   :src="FILE_URL+task.task_photo"
                   min-height="180"
                   max-height="180"/>

            <v-img v-else :src="require('@/assets/no-image.png')"
                   min-height="180"
                   max-height="180"/>

            <v-card-text class="flex">
              <div class="text-center mb-2">

                <p>
                  {{ task.task_name }}
                </p>

                <h3 class="text--primary mt-2">
                  Total : {{ task.responses_total }}
                </h3>

              </div>

              <div class="d-flex justify-space-between mb-1">
                <v-icon small left>mdi-check-circle-outline</v-icon>
                {{ task.validated_total }}-Validées
                <v-spacer/>
                {{ task.validated_percent }}%
              </div>

              <v-progress-linear :value="task.validated_percent"
                                 striped
                                 :color="setColor(task.validated_percent)"
                                 rounded
                                 height="5"></v-progress-linear>

              <div class="d-flex justify-space-between mt-4 mb-1">
                <v-icon small left>mdi-close-circle-outline</v-icon>
                {{ task.refused_total }}-Réfusées
                <v-spacer/>
                {{ task.refused_percent }}%
              </div>

              <v-progress-linear :value="task.refused_percent"
                                 striped
                                 :color="setColor(task.refused_percent)"
                                 rounded
                                 height="5"></v-progress-linear>


              <v-row justify="center" align="center" class="mt-2">
                <v-col cols="12" lg="6"
                       class="text-center"
                       :class="i === 0 ? 'b-r' : ''"
                       v-for="(raison,i) in task.raisons"
                       :key="i">
                  <v-progress-circular
                      :rotate="360"
                      :size="50"
                      :width="5"
                      :value="raison.percent"
                      :color="setColor(raison.percent)">
                    <small>{{ raison.percent }}%</small>
                  </v-progress-circular>
                  <div class="d-block"> Total : {{ raison.total }}</div>
                  <strong class="text--primary d-block">{{ raison.name }}</strong>
                </v-col>
              </v-row>


            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

    </v-container>
  </div>
</template>

<script>
import Toolbar from "../Toolbar";
import {HTTP} from "@/http-common";
import NoResult from "../../../components/NoResult";

export default {
  components: {NoResult, Toolbar},
  data() {
    return {
      loading: false,
      FILE_URL: process.env.VUE_APP_FILE_URL,
      tasks: [],
    }
  },
  methods: {
    getTasks() {
      this.loading = true
      this.$Progress.start()
      HTTP.get('/tasks/final/group').then((res) => {
        this.tasks = res.data.data
        this.loading = false
        this.$Progress.finish()
      }).catch(err => {
        this.loading = false
        this.$Progress.fail()
        console.log(err)
      })
    },
    setColor(el) {
      if (el <= 30) {
        return 'red'
      }
      if (el > 30 && el <= 60) {
        return 'yellow'
      }
      if (el > 60 && el <= 90) {
        return 'primary'
      }
      if (el > 90 && el <= 100) {
        return 'success'
      }
    }
  },
  created() {
    this.getTasks()
  }
}
</script>

<style scoped>

</style>